<template>
  <div class="container">
    <!-- Language -->
    <div style="position: fixed; right: 20px; top: 20px">
      <div class="btn-login" style="background-color: #00BCD4; border-radius: 5px;">
      </div>
    </div>
    <!-- Login form -->
    <div class="login-container">
      <div style="text-align: left; width: 350px">
        <span style="font-size: 20px;">{{ $t('register.password_reset_set_pass_title') }}</span>
        <span
          style="display: block; margin-top: 15px; margin-bottom: 15px; color: #707070"
        >{{ $t('register.password_reset_set_pass_content') }}</span>
        <form role="form" method="post" @submit.prevent="passwodResetSetPassword">
          <div class="box-body">
            <div class="form-group">
              <div style="padding-bottom: 15px">
                <label>{{$t('register.password_reset_set_pass_btn1')}}</label>
                <input
                  style="width:100%;"
                  type="password"
                  class="form-control"
                  v-model="password1"
                  @input="validatePassword(password1)"
                />
                <span
                  :class="{ password_length_count : valid_password_length, show_password_length : password_typed }"
                  class="password_length_modal"
                  style="z-index:999"
                >{{ password_length }}</span>
                <div v-show="password_typed" class="lnu_container">
                  <p
                    :class="{ got_lowercase : contain_lowercase}"
                  >{{$t('register.lowercase')}}</p>
                  <p :class="{ got_number : contain_number }">{{$t('register.digit')}}</p>
                  <p
                    :class="{ got_uppercase : contain_uppercase }"
                  >{{$t('register.uppercase')}}</p>
                </div>
              </div>
              <div>
                <label>{{$t('register.password_reset_set_pass_btn2')}}</label>
                <input style="width:100%;" type="password" class="form-control" v-model="password2" />
              </div>
            </div>
          </div>
          <div class="box-footer">
            <button
              type="submit"
              class="btn btn-primary float-right"
            >{{$t('register.password_reset_set_pass_btn3')}}</button>
          </div>
        </form>
      </div>
    </div>
    <snack-bar v-for="snackbar in GET_SNACK_BAR" :key="snackbar.id" :item="snackbar"></snack-bar>
    <loading-bar :isLoading="isLoading"></loading-bar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    uidb64: null,
    token: null,
    password1: null,
    password2: null,
    locale: "vi",
    isLoading: false,
    password_typed: false,
    contain_lowercase: false,
    contain_uppercase: false,
    contain_number: false,
    password_length: 0,
    valid_password_length: false,
    valid_password: false
  }),
  computed: {
    ...mapGetters(["GET_SNACK_BAR"])
  },
  methods: {
    changeLanguage: function() {
      let locale = this.$store.state.language.selectedLocale;
      if (locale == "en") {
        this.$i18n.locale = "vi";
        this.locale = "vi";
      } else {
        this.$i18n.locale = "en";
        this.locale = "en";
      }
      this.$store.dispatch("changeLanguage", this.locale);
    },
    passwodResetSetPassword: function() {
      if (!this.valid_password) {
        this.ON_SHOW_TOAST({
          message: this.$t('register.validatePasswordError'),
          styleType: "danger"
        });
      } else if (this.password1 !== this.password2) {
        this.ON_SHOW_TOAST({
          message: this.$t('register.checkNewPasswordAndOldPassword'),
          styleType: "danger"
        });
      } else {
        let payload = {
          uidb64: this.uidb64,
          token: this.token,
          password1: this.password1,
          password2: this.password2
        };

        this.ACCOUNT_PASSWORD_RESET_SET_PASSWORD(payload)
          .then(
            function() {
              this.ON_SHOW_TOAST({
                message: "success",
                styleType: "success"
              });
              this.$router.push({ name: "checkLogin" });
            }.bind(this)
          )
          .catch(
            function(error) {
              this.ON_SHOW_TOAST({
                message: error.response.data.message,
                styleType: "danger"
              });
            }.bind(this)
          );
      }
    },
    validatePassword: function(password) {
      this.password_length = password.length;
      if (this.password_length > 7) {
        this.valid_password_length = true;
      } else {
        this.valid_password_length = false;
      }
      if (this.password_length > 0) {
        this.password_typed = true;
      } else {
        this.password_typed = false;
      }
      this.contain_lowercase = /[a-z]/.test(password);
      this.contain_uppercase = /[A-Z]/.test(password);
      this.contain_number = /\d/.test(password);
      if (
        this.contain_lowercase == true &&
        this.contain_uppercase == true &&
        this.contain_number == true &&
        this.valid_password_length == true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    ...mapActions(["ON_SHOW_TOAST", "ACCOUNT_PASSWORD_RESET_SET_PASSWORD"])
  },
  mounted() {
    this.uidb64 = this.$route.params.uidb64;
    this.token = this.$route.params.token;
  }
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.login-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn-login {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  border-radius: 5px;
}
.lnu_container {
  display: block;
  margin: 10px auto;
  margin-bottom: 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}
.lnu_container p {
  width: 30%;
  height: auto;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  border-radius: 2px;
  color: rgba(71, 87, 98, 0.8);
  background: linear-gradient(to right, #06a65a 50%, #eee 50%);
  background-size: 200% 100%;
  background-position: right;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.show_password_length {
  display: inline-block !important ;
}
.password_length {
  padding: 2px 8px;
  position: absolute;
  top: 42px;
  right: 22px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #dd4b39;
  color: white;
  border-color: #d73925;
  border-radius: 4px;
  font-size: 13px;
  display: none;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}
.password_length_modal {
  padding: 2px 8px;
  position: absolute;
  top: 44.6%;
  right: 5px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #dd4b39;
  color: white;
  border-color: #d73925;
  border-radius: 4px;
  font-size: 13px;
  display: none;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}
.password_length_count {
  background: #06a65a;
  color: rgba(255, 255, 255, 0.9);
}
.got_lowercase,
.got_uppercase,
.got_number {
  background-position: left !important;
  color: rgba(255, 255, 255, 0.9) !important;
}
</style>